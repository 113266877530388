const LikedReviewsCookieName = "l_r_c_n";
const LikedReviewsCookieLifetimeMinutes = 1051897;


function SetLikedReviewsCookie(likedReviews) {
    const d = new Date();
    d.setTime(d.getTime() + (3600*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = LikedReviewsCookieName + "=" + JSON.stringify(likedReviews) + ";" + expires + ";path=/;samesite=lax";
};

function GetLikedReviewsCookie() {
    let name = LikedReviewsCookieName + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length).replaceAll('"', '');
        }
    }
    return null;
};

window.LikeReview = function (ratingId) {
    let likedReviews = GetLikedReviews();

    if (likedReviews == null) {
        likedReviews = new Array();
    }
    
    likedReviews.push(ratingId);
    
    SetLikedReviewsCookie(likedReviews);
};

window.GetLikedReviews = function () {
    var likedReviews = GetLikedReviewsCookie();
    return JSON.parse(likedReviews);
};
